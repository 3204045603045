//require('./jquery.min');
require('./bootstrap');
//require('./bootstrap-datetimepicker.min.js');
//require('../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js');
import 'select2';
setTimeout(function() {
    $('[data-toggle="tooltip"]').tooltip();

}, 1500);


// $(window).bind('resize load', function() {
//     if ($(this).width() < 992) {
//         $('#orderDetailCollapse').removeClass('show');
//         $('#orderDetailCollapseBtn').attr("aria-expanded","false");
//     } else {
//         $('#orderDetailCollapse').addClass('show');
//         $('#orderDetailCollapseBtn').attr("aria-expanded","true");
//     }
// });
$(document).ready(function(){
    
    $(".errorTip [data-toggle='tooltip']").tooltip({
        template: '<div class="tooltip errorToolTip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      })

    $('.changePassword').on('shown.bs.modal', function() {     
        $(".errorTip [data-toggle='tooltip']").tooltip({
            template: '<div class="tooltip errorToolTip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        }) 
    }); 
    $('.js-example-basic-single').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'above',
        forceabove:true,
        allowHtml: true
    });
    $('.aboveSelect').select2({
        minimumResultsForSearch: -1,
        dropdownParent: $('.abovePosition')
    });

    $('.expertChatBtn').on('click',function(){
        $('.chat-portion').addClass('show');
    });
    $('.closeExpert').on('click',function(){
        $('.chat-portion').removeClass('show');
    });
    //$('form [data-toggle="tooltip"]').tooltip('show')
    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("mainContent").style.marginLeft = "250px";
        document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    }

    function closeNav() {
        document.getElementById("navbarSupportedContent").style.width = "0";
        document.getElementById("mainContent").style.marginLeft= "0";
        document.body.style.backgroundColor = "white";
    }
    $('.carousel').carousel({
        interval: false,
    });

    //$('[data-toggle="tooltip"]').tooltip();

    $('.slide__right').on('click',function(){
        $(this).closest('.slider').toggleClass('move-right');

        if ($(".slider").hasClass("move-right")== true) {
            $(this).addClass("active");
        }
        else{
            $(this).removeClass("active");
        }
    })

    $('.eye').on('click',function(){
        //$(this).toggleClass("eye-on");
        var typeInput= $('#password').attr('type');
        // console.log(test);
        if(typeInput == 'password'){
            $('#password').attr('type', 'text');

        }
        else{
            $('#password').attr('type', 'password');
        }

    })

    $("input[type='password']").on('keyup',function(){
        var val = $(this).val();
        if(val.length==0){
            $(".eye-off,.eye-on").css("display", "none");
            $('#password').attr('type','password');
        }
        else{
            var typeInput= $('#password').attr('type');
            if(typeInput == 'password'){
                //$('#password').attr('type', 'text');
                $('.eye-off').css("display", "inline");
                $('.eye-on').css("display", "none");
            }
            else{
                //$('#password').attr('type', 'password');
                $('.eye-off').css("display", "none");
                $('.eye-on').css("display", "inline");
            }
        }
    });

    // $("input[type='password']").focus(function(){
    //    $(".eye-off").css("display", "inline");
    // });


    $('.eye-off').on('click',function(){
        var typeInput= $('#password').attr('type');
        if(typeInput == 'password'){
            //$('#password').attr('type', 'text');
            $(this).css("display", "inline");
            $('.eye-on').css("display", "none");
        }
        else{
            //$('#password').attr('type', 'password');
            $(this).css("display", "none");
            $('.eye-on').css("display", "inline");
        }

    })

    $('.eye-on').on('click',function(){
        var typeInput= $('#password').attr('type');
        if(typeInput == 'password'){
            //$('#password').attr('type', 'text');
            $(this).css("display", "none");
            $('.eye-off').css("display", "inline");
        }
        else{
            //$('#password').attr('type', 'password');
            $(this).css("display", "inline");
            $('.eye-off').css("display", "none");
        }
    })

    $('form').each(function() {

        $("input[type='email'],input[type='password'],input[type='tel']").on("keyup", function(){
            var blankFields=$("input[type='email'],input[type='password'],input[type='tel'] ")
                .filter(function( index ) {
                    return $(this).val().length === 0;
                }).length;


            if(blankFields==0){
                $(".submitBtn").removeClass("btn-grayish-blue-light").addClass("btn-orange-bright");
            }
            else{
                $('.submitBtn').removeClass("btn-orange-bright").addClass("btn-grayish-blue-light");
            }
        });
    });


});

